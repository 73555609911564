import { CLASSES } from 'Constants/ActionTypes';
const initialState = {
    classTecUserTableSelVal: JSON.stringify({}),
};
// 用户配置
const classes = (state = initialState, action) => {
    switch (action.type) {
        // 班主任选中
        case CLASSES.CLASS_TEACHER_USER_TABLE_SELECTED:
            return {
                ...state,
                classTecUserTableSelVal: action.classTecUserTableSelVal,
            };
        default:
            return state;
    }
};
export default classes;
