
/*
* actions常量
* */

export const SETTING = {
  CE_SHI: 'CE_SHI'
};

// 公共
export const COMMON = {
  NAV_LEFT_STATUS: 'NAV_LEFT_STATUS', //菜单栏状态
  TOP_TAB_STATUS: 'TOP_TAB_STATUS', //topTab导航栏状态
  SET_NAV_MENU_ITEM: 'SET_NAV_MENU_ITEM', //打开的菜单和topTab统一数据
  SET_NAV_MENU_ITEM_TARGET: 'SET_NAV_MENU_ITEM_TARGET', //打开的菜单和topTab统一数据
  DELETE_NAV_MENU_ITEM: 'DELETE_NAV_MENU_ITEM',
  GET_AREA_TREE: 'GET_AREA_TREE',
  GET_SCHOOL_TREE: 'GET_SCHOOL_TREE',
  GET_USER_INFO: 'GET_USER_INFO', //获取用户信息
  GET_UUID: 'GET_UUID',
  GET_ORG_TREE: 'GET_ORG_TREE',
  GET_AUTH_CLASS: 'GET_AUTH_CLASS',
  GET_ROLE_TREE: 'GET_ROLE_TREE',
  GET_APP_GROUP_ALL: 'GET_APP_GROUP_ALL',
  GET_ALL_RESOURCE_CLASS: 'GET_ALL_RESOURCE_CLASS',
  GET_ALL_COURSE_CLASS: 'GET_ALL_COURSE_CLASS',
  SET_SELECTION_CHANGE: 'SET_SELECTION_CHANGE',
  GET_COURSE_DATA: 'GET_COURSE_DATA',
  SET_UPLOAD_RESOURCE_INFO: 'SET_UPLOAD_RESOURCE_INFO',
  SET_UPLOAD_THUMB_INFO: 'SET_UPLOAD_THUMB_INFO',
  GET_ALL_EXAM_CLASS: 'GET_ALL_EXAM_CLASS',
  MODIFY_MY_PW: 'MODIFY_MY_PW',
  SET_IMPORT_INFO: 'SET_IMPORT_INFO',
  IS_IMPORT_STATUS: 'IS_IMPORT_STATUS',
  GET_TOKEN_DEMO: 'GET_TOKEN_DEMO', //测试token 失效问题
};
// 登录
export const LOGIN = {
  LOGIN: 'LOGIN', // 登录
  LOGIN_OUT: 'LOGIN_OUT', // 推出登录
  LOGIN_CHECK: 'LOGIN_CHECK', // 登录验证
};
// 地区管理
export const REGION = {
  GET_REGION_TREE_DATA: 'GET_REGION_TREE_DATA', //获取地区管理DOM树
  ADD_REGION_TREE: 'ADD_REGION_TREE', //添加dom树
  EDIT_REGION_TREE: 'EDIT_REGION_TREE', //编辑dom树
  DELETE_REGION_TREE: 'DELETE_REGION_TREE', //删除dom树
};
// 机构管理、学校管理
export const INSTITUTION = {
  GET_INSTITUTION_LIST: 'GET_INSTITUTION_LIST',
  GET_INSTITUTION_DETAILS: 'GET_INSTITUTION_DETAILS',
  INSTITUTION_ADD: 'INSTITUTION_ADD',
  INSTITUTION_EDIT: 'INSTITUTION_EDIT',
  INSTITUTION_DELETE: 'INSTITUTION_DELETE',
};
// 班级管理
export const CLASSES = {
  GET_CLASSES_LIST: 'GET_CLASSES_LIST',
  GET_CLASSES_DETAILS: 'GET_CLASSES_DETAILS',
  CLASSES_ADD: 'CLASSES_ADD',
  CLASSES_EDIT: 'CLASSES_EDIT',
  CLASSES_DELETE: 'CLASSES_DELETE',
  CLASSES_SET_COURSE: 'CLASSES_SET_COURSE',
  GET_CLASSES_STUDENT: 'GET_CLASSES_STUDENT',
  ADD_CLASSES_STUDENT: 'ADD_CLASSES_STUDENT',
  CLASSES_UPLOAD_FILE: 'CLASSES_UPLOAD_FILE',
  GET_CLASSES_IMPORT_TEMPLATE: 'GET_CLASSES_IMPORT_TEMPLATE',
  EDIT_CLASSES_STUDENT_TABLE: 'EDIT_CLASSES_STUDENT_TABLE',
  CLASS_TEACHER_USER_TABLE_SELECTED: 'CLASS_TEACHER_USER_TABLE_SELECTED',
};
// 系统配置
export const SYS_CONFIG = {
  GET_SYS_CONFIG_LIST: 'GET_SYS_CONFIG_LIST',
  GET_SYS_CONFIG_DETAILS: 'GET_SYS_CONFIG_DETAILS',
  SYS_CONFIG_ADD: 'SYS_CONFIG_ADD',
  SYS_CONFIG_EDIT: 'SYS_CONFIG_EDIT',
  SYS_CONFIG_DELETE: 'SYS_CONFIG_DELETE',
};
// 第三方应用分组
export const APPLICATION_GROUP = {
  GET_APPLICATION_GROUP_LIST: 'GET_APPLICATION_GROUP_LIST',
  GET_APPLICATION_GROUP_DETAILS: 'GET_APPLICATION_GROUP_DETAILS',
  APPLICATION_GROUP_ADD: 'APPLICATION_GROUP_ADD',
  APPLICATION_GROUP_EDIT: 'APPLICATION_GROUP_EDIT',
  APPLICATION_GROUP_DELETE: 'APPLICATION_GROUP_DELETE',
};
// 第三方应用
export const APPLICATION = {
  GET_APPLICATION_LIST: 'GET_APPLICATION_LIST',
  GET_APPLICATION_DETAILS: 'GET_APPLICATION_DETAILS',
  APPLICATION_ADD: 'APPLICATION_ADD',
  APPLICATION_EDIT: 'APPLICATION_EDIT',
  APPLICATION_DELETE: 'APPLICATION_DELETE',
};
// 数据总览
export const OVERVIEW = {
  GET_OVERVIEW_DATA: 'GET_OVERVIEW_DATA',
  GET_OVERVIEW_DATA_DETAILS: 'GET_OVERVIEW_DATA_DETAILS',
  ADD_OVERVIEW_DATA: 'ADD_OVERVIEW_DATA',
  EDIT_OVERVIEW_DATA: 'EDIT_OVERVIEW_DATA',
  DELETE_OVERVIEW_DATA: 'DELETE_OVERVIEW_DATA',
};
// 用户管理
export const USER_MAN = {
  GET_USER_MAN_LIST: 'GET_USER_MAN_LIST',
  GET_USER_MAN_DETAILS: 'GET_USER_MAN_DETAILS',
  USER_MAN_ADD: 'USER_MAN_ADD',
  USER_MAN_EDIT: 'USER_MAN_EDIT',
  USER_MAN_DELETE: 'USER_MAN_DELETE',
  USER_MAN_MODIFY_PW: 'USER_MAN_MODIFY_PW',
  USER_UPLOAD_FILE: 'USER_UPLOAD_FILE',
  GET_USER_MAN_IMPORT_TEMPLATE: 'GET_USER_MAN_IMPORT_TEMPLATE',
};
// 角色管理
export const PRIVILEGE_ROLE = {
  GET_PRIVILEGE_ROLE_LIST: 'GET_PRIVILEGE_ROLE_LIST',
  GET_PRIVILEGE_ROLE_DETAILS: 'GET_PRIVILEGE_ROLE_DETAILS',
  PRIVILEGE_ROLE_ADD: 'PRIVILEGE_ROLE_ADD',
  PRIVILEGE_ROLE_EDIT: 'PRIVILEGE_ROLE_EDIT',
  PRIVILEGE_ROLE_DELETE: 'PRIVILEGE_ROLE_DELETE',
  GET_ROLE_AUTH_TREE: 'GET_ROLE_AUTH_TREE',
  GET_ROLE_AUTH_DETAILS: 'GET_ROLE_AUTH_DETAILS',
  ROLE_SET_RULE: 'ROLE_SET_RULE',
  GET_ALL_ROLE_LIST: 'GET_ALL_ROLE_LIST',
  ROLE_SET_USER: 'ROLE_SET_USER',

};
// 权限管理
export const PRIVILEGE_MAN = {
  GET_PRIVILEGE_MAN_LIST: 'GET_PRIVILEGE_MAN_LIST',
  GET_PRIVILEGE_MAN_DETAILS: 'GET_PRIVILEGE_MAN_DETAILS',
  PRIVILEGE_MAN_ADD: 'PRIVILEGE_MAN_ADD',
  PRIVILEGE_MAN_EDIT: 'PRIVILEGE_MAN_EDIT',
  PRIVILEGE_MAN_DELETE: 'PRIVILEGE_MAN_DELETE',
};

// 角色组管理
export const PRIVILEGE_ROLE_GROUP = {
  GET_PRIVILEGE_ROLE_GROUP_LIST: 'GET_PRIVILEGE_ROLE_GROUP_LIST',
  GET_PRIVILEGE_ROLE_GROUP_DETAILS: 'GET_PRIVILEGE_ROLE_GROUP_DETAILS',
  PRIVILEGE_ROLE_GROUP_ADD: 'PRIVILEGE_ROLE_GROUP_ADD',
  PRIVILEGE_ROLE_GROUP_EDIT: 'PRIVILEGE_ROLE_GROUP_EDIT',
  PRIVILEGE_ROLE_GROUP_DELETE: 'PRIVILEGE_ROLE_GROUP_DELETE',
};
// 资源管理
export const RESOURCE_MAN = {
  GET_RESOURCE_MAN_LIST: 'GET_RESOURCE_MAN_LIST',
  GET_RESOURCE_MAN_DETAILS: 'GET_RESOURCE_MAN_DETAILS',
  RESOURCE_MAN_ADD: 'RESOURCE_MAN_ADD',
  RESOURCE_MAN_EDIT: 'RESOURCE_MAN_EDIT',
  RESOURCE_MAN_DELETE: 'RESOURCE_MAN_DELETE',
  GET_EXPORT_PATH: 'GET_EXPORT_PATH',

};
// 资源分类
export const RESOURCE_CLASS = {
  GET_RESOURCE_CLASS_LIST: 'GET_RESOURCE_CLASS_LIST',
  GET_RESOURCE_CLASS_DETAILS: 'GET_RESOURCE_CLASS_DETAILS',
  RESOURCE_CLASS_ADD: 'RESOURCE_CLASS_ADD',
  RESOURCE_CLASS_EDIT: 'RESOURCE_CLASS_EDIT',
  RESOURCE_CLASS_DELETE: 'RESOURCE_CLASS_DELETE',
};
// 课程分类
export const COURSE_CLASS = {
  GET_COURSE_CLASS_LIST: 'GET_COURSE_CLASS_LIST',
  GET_COURSE_CLASS_DETAILS: 'GET_COURSE_CLASS_DETAILS',
  COURSE_CLASS_ADD: 'COURSE_CLASS_ADD',
  COURSE_CLASS_EDIT: 'COURSE_CLASS_EDIT',
  COURSE_CLASS_DELETE: 'COURSE_CLASS_DELETE',
};
// 课程管理
export const COURSE_MAN = {
  GET_COURSE_MAN_LIST: 'GET_COURSE_MAN_LIST',
  GET_COURSE_MAN_DETAILS: 'GET_COURSE_MAN_DETAILS',
  COURSE_MAN_ADD: 'COURSE_MAN_ADD',
  COURSE_MAN_EDIT: 'COURSE_MAN_EDIT',
  COURSE_MAN_DELETE: 'COURSE_MAN_DELETE',
  COURSE_SET_RESOURCE: 'COURSE_SET_RESOURCE',
  SET_UPLOAD_COURSE_INFO: 'SET_UPLOAD_COURSE_INFO',
  SET_UPLOAD_THUMB_INFO: 'SET_UPLOAD_THUMB_INFO',
};
// 试题管理
export const SUBJECT_MAN = {
  GET_SUBJECT_MAN_LIST: 'GET_SUBJECT_MAN_LIST',
  GET_SUBJECT_MAN_DETAILS: 'GET_SUBJECT_MAN_DETAILS',
  SUBJECT_MAN_ADD: 'SUBJECT_MAN_ADD',
  SUBJECT_MAN_EDIT: 'SUBJECT_MAN_EDIT',
  SUBJECT_MAN_DELETE: 'SUBJECT_MAN_DELETE',
};
// 试卷管理
export const TEST_PAPER_MAN = {
  GET_TEST_PAPER_MAN_LIST: 'GET_TEST_PAPER_MAN_LIST',
  TEST_PAPER_MAN_DELETE: 'TEST_PAPER_MAN_DELETE',
  SAVE_EXAM_PAPER: 'SAVE_EXAM_PAPER',
  GET_STEP_ONE_DETAILS: 'GET_STEP_ONE_DETAILS',
  EXAM_PAPER_SET_QUESTION: 'EXAM_PAPER_SET_QUESTION',
  GET_QUESTIONS_LIST: 'GET_QUESTIONS_LIST',
  SET_CORE: 'SET_CORE',
  SET_STEP_STATUS: 'SET_STEP_STATUS',
  AUTOMATIC_ADD: 'AUTOMATIC_ADD',
};
// 考试类型
export const EXAM_CLASS = {
  GET_EXAM_CLASS_LIST: 'GET_EXAM_CLASS_LIST',
  GET_EXAM_CLASS_DETAILS: 'GET_EXAM_CLASS_DETAILS',
  EXAM_CLASS_ADD: 'EXAM_CLASS_ADD',
  EXAM_CLASS_EDIT: 'EXAM_CLASS_EDIT',
  EXAM_CLASS_DELETE: 'EXAM_CLASS_DELETE',
};
// 考试参与学生列表
export const EXAM_RESULTS = {
  GET_TABLE_LIST: 'GET_TABLE_LIST',
  GET_EXAM_ALL_CLASSES: 'GET_EXAM_ALL_CLASSES',
};
// 考试参与学生列表
export const SIM_GAME_RESULTS = {
  GET_TABLE_LIST: 'GET_TABLE_LIST',
};
// 考试学生详情
export const EXAM_RESULT_DETAILS = {
  GET_ANSWER_LIST: 'GET_ANSWER_LIST',
};
// 考试学生详情
export const SIM_GAME_RESULTS_DETAILS = {
  GET_ANSWER_LIST: 'GET_ANSWER_LIST',
};
// 考试管理
export const EXAM_MAN = {
  GET_EXAM_MAN_LIST: 'GET_EXAM_MAN_LIST',
  GET_EXAM_MAN_DETAILS: 'GET_EXAM_MAN_DETAILS',
  EXAM_MAN_ADD: 'EXAM_MAN_ADD',
  EXAM_MAN_EDIT: 'EXAM_MAN_EDIT',
  EXAM_MAN_DELETE: 'EXAM_MAN_DELETE',
  EDIT_PUBLISH_STATUS: 'EDIT_PUBLISH_STATUS',
  SELECT_TEST_PAPER_FUN: 'SELECT_TEST_PAPER_FUN',
  EXAM_ASSOCIATED_CLASSES: 'EXAM_ASSOCIATED_CLASSES',
};
// 仿真游戏
export const EXAM_PLAY = {
  GET_EXAM_PLAY_LIST: 'GET_EXAM_PLAY_LIST',
  GET_EXAM_PLAY_DETAILS: 'GET_EXAM_PLAY_DETAILS',
  EXAM_PLAY_ADD: 'EXAM_PLAY_ADD',
  EXAM_PLAY_EDIT: 'EXAM_PLAY_EDIT',
  EXAM_PLAY_DELETE: 'EXAM_PLAY_DELETE',
  SELECT_TEST_PAPER_FUN: 'SELECT_TEST_PAPER_FUN',
};
// 统计分析
export const STATISTICS = {
  GET_CHART_LIST: 'GET_CHART_LIST',
  GET_CHART_SEARCH_TREE: 'GET_CHART_SEARCH_TREE',
  GET_CHART_AREA_SCHOOL: 'GET_CHART_AREA_SCHOOL',
  GET_TEACHER_ACTIVITY_LIST: 'GET_TEACHER_ACTIVITY_LIST',
  GET_TEACHER_ACTIVITY_DETAILS: 'GET_TEACHER_ACTIVITY_DETAILS',
  GET_SCHOOL_INTERACT: 'GET_SCHOOL_INTERACT',
  GET_INTERACTIVE_SCORE_LIST: 'GET_INTERACTIVE_SCORE_LIST',
  GET_INTERACTIVE_SCORE_DETAILS: 'GET_INTERACTIVE_SCORE_DETAILS',
  GET_MINCRO_LESSON_LIST: 'GET_MINCRO_LESSON_LIST',
  GET_TEACHING_MATERIAL_LIST: 'GET_TEACHING_MATERIAL_LIST',
  GET_PARENT_ACTIVITY_LIST: 'GET_PARENT_ACTIVITY_LIST',
  GET_MIC_BOOK_DETAILS: 'GET_MIC_BOOK_DETAILS',
  GET_TEST_SCORE_LIST: 'GET_TEST_SCORE_LIST',
  GET_TEST_SCORE_DETAILS: 'GET_TEST_SCORE_DETAILS',
  GET_STUDENT_TOTAL_CONTRAST: 'GET_STUDENT_TOTAL_CONTRAST',
  GET_INTERACTIVE_CHART_LIST: 'GET_INTERACTIVE_CHART_LIST',
  GET_INTERACTIVE_TABLE_LIST: 'GET_INTERACTIVE_TABLE_LIST',
};

// 护蕾大屏
export const PROTECT_CHILD_STATISTICS = {
  GET_LEFT_DATA: 'GET_LEFT_DATA',
}
// 大屏统计
export const LARGE_VISUALIZATION_SCREEN = {
  GET_COUNT_ANALYSIS: 'GET_COUNT_ANALYSIS',
  GET_YEAR_COUNT: 'GET_YEAR_COUNT',
  GET_ACTIVITY_RAN: 'GET_ACTIVITY_RAN',
  GET_TOTAL_BY_GRADE: 'GET_TOTAL_BY_GRADE',
  GET_SEX_GRADE: 'GET_SEX_GRADE',
  GET_TEACHER_LESSON: 'GET_TEACHER_LESSON',
};
// 文章公共
export const ARTICLE_COMMON = {
  GET_TABLE_LIST: 'GET_TABLE_LIST',
  TABLE_DETAILS: 'TABLE_DETAILS',
  TABLE_DELETE: 'TABLE_DELETE',
  TABLE_ADD: 'TABLE_ADD',
  TABLE_EDIT: 'TABLE_EDIT',
};
// 小程序
export const APPLETS = {
  GET_TABLE_LIST: 'GET_TABLE_LIST',
  TABLE_DETAILS: 'TABLE_DETAILS',
  TABLE_DELETE: 'TABLE_DELETE',
  TABLE_ADD: 'TABLE_ADD',
  TABLE_EDIT: 'TABLE_EDIT',
};