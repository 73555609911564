// 生产环境api地址配置
function envAxiosUrl() {
    let baseURL = '/';
    if (process.env.REACT_ENV_AXIOS_URL === 'dev_production') {
        baseURL = 'https://api1.trsafety.com/';
        // baseURL = 'https://dev.trsafety.com';
        return baseURL
    } else if (process.env.REACT_ENV_AXIOS_URL === 'production') {
        baseURL = 'https://api2.trsafety.com/';
        return baseURL
    } else if (process.env.REACT_ENV_AXIOS_URL === 'oss_production') {
        baseURL = 'https://ossdev.trsafety.com/';
        return baseURL
    } else {
        return baseURL
    }
}

// 菜单配置
const menuList = [
    {
        title: '首页',
        key: '/index',
        icon: 'desktop',
    },
    {
        title: '系统管理',
        icon: 'home',
        key: 'settingAdmin',
        children: [
            {
                title: '系统配置',
                key: '/settingAdmin/sysConfig',
            },
            {
                title: '三方应用分组',
                key: '/settingAdmin/applicationGroup',
            },
            {
                title: '三方应用',
                key: '/settingAdmin/application',
            },
            {
                title: '行政区管理',
                key: '/settingAdmin/region',
            }, {
                title: '数据总览',
                key: '/settingAdmin/overview',
            },
        ]
    },
    {
        title: '机构管理',
        icon: 'bank',
        key: 'institution',
        children: [
            {
                title: '机构管理',
                key: '/settingAdmin/institution',
            },
            {
                title: '学校管理',
                key: '/settingAdmin/school',
            },
            {
                title: '班级管理',
                key: '/settingAdmin/classes'
            },
        ]
    },
    {
        title: '用户管理',
        icon: 'user',
        key: '/userAdmin',
        children: [
            {
                title: '用户管理',
                key: '/userAdmin/userMan',
            },
            {
                title: '角色组管理',
                key: '/userAdmin/privilegeRoleGroup',
            }, {
                title: '角色管理',
                key: '/userAdmin/privilegeRole',
            },
            {
                title: '权限管理',
                key: '/userAdmin/privilegeMan',
            },
            // {
            //     title: '权限分类',
            //     key: '/userAdmin/privilegeClass',
            // },
        ]
    },
    {
        title: '资源管理',
        icon: 'shopping',
        key: '/resource',
        children: [
            {
                title: '资源管理',
                key: '/resource/resourceMan',
            },
            {
                title: '资源分类',
                key: '/resource/resourceClass',
            },
        ]
    },
    {
        title: '课程管理',
        icon: 'calculator',
        key: '/course',
        children: [
            {
                title: '课程管理',
                key: '/course/courseMan',
            },
            {
                title: '课程分类',
                key: '/course/courseClass',
            },
        ]
    },
    {
        title: '题库管理',
        icon: 'folder-open',
        key: '/questionBank',
        children: [
            {
                title: '试题管理',
                key: '/questionBank/subjectMan',
            },
            {
                title: '试卷管理',
                key: '/questionBank/testPaperMan',
            },
            {
                title: '考试类型',
                key: '/questionBank/examClass',
            },
            {
                title: '考试管理',
                key: '/questionBank/examMan',
            },
            {
                title: '仿真游戏',
                key: '/questionBank/simulationGame',
            },
        ]
    },
    {
        title: '测评管理',
        icon: 'folder-open',
        key: '/testEvaluation',
        children: [
            {
                title: '测评试题',
                key: '/testEvaluation/examinationQuestions',
            },
        ]
    },
    {
        title: '大数据分析',
        key: '/statistics',
        icon: 'pie-chart',
        children: [
            {
                title: '全国分布图',
                key: '/statistics/nationwide',
                // 全国地图，鼠标放上 显示人数
            },
            {
                title: '省级统计',
                key: '/statistics/province',
                // 这个省下各个市的使用数量（基础柱状图）
            },
            {
                title: '市级统计',
                key: '/statistics/city',
                // 这个市下各个区或者县的使用数量（基础柱状图）
            },
            {
                title: '区级统计',
                key: '/statistics/area',
                // 这个区下各个学校的使用数量 （基础柱状图）
            },
            {
                title: '校级统计',
                key: '/statistics/school',
                // 获取学校下所有年级，，通过点击年级获取年级下所有的班级
                // 本学校下，相同年级下各个班级的使用数量。（基础柱状图）
                children: [
                    {
                        title: '教师活跃度统计',
                        key: '/statistics/school/allTeacher',
                        // 本学校下所有老师的使用次数，（基础柱状图）
                    }, {
                        title: '教师课时详情',
                        key: '/statistics/school/aloneTeacher',
                        // 老师点击客户端使用轨迹，一个表格(老师姓名，登录系统时间，进入场景的时间，进入电子教材的时间，账户登录时长)，
                        // 老师的名称搜索，老师姓名，登录系统时间区间，
                    },
                    {
                        title: '互动游戏统计',
                        key: '/statistics/school/interactiveGames',
                    }, {
                        title: '考试成绩统计',
                        key: '/statistics/school/testScores',
                    },
                ]
            },
            {
                title: '家长活跃度统计',
                key: '/statistics/ar11ea',
                // 家长的登录次数，（基础柱状图）
            },
        ]
    },
];

//全局Input最大长度
let INPUT_MAX_LENGTH = 50;

export {
    envAxiosUrl,
    menuList,
    INPUT_MAX_LENGTH,
};
