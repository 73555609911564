import React from 'react'
import {
  HashRouter as
    BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import App from '../app'
import Admin from 'Containers/admin'
import HocPrivateRoute from 'Components/HocPrivateRoute';
import routesConfig from './config'

const PrivateRoute = HocPrivateRoute(Route);
const RoutesIndex = (
  <BrowserRouter>
    <App>
      <Switch>
        {routesConfig.map((val, index) => {
          if (!val.children) {
            if (val.auth) {
              return <PrivateRoute key={val.path} exact={val.exact} path={val.path} component={val.component} />
            } else {
              return <Route key={val.path} exact={val.exact} path={val.path} component={val.component} />
            }
          }
          else if (val.component === "Admin") {
            return <Admin key={index}>
              <Switch>
                {val.children.map((item, indexItem) => {
                  return <PrivateRoute key={indexItem} exact={item.exact} path={item.path} component={item.component} />
                })}
                <Redirect to="/error/404" />
              </Switch>
            </Admin>
          } else {
            return null;
          }
        })}
      </Switch>
    </App>
  </BrowserRouter>
);
export default RoutesIndex
