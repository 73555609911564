import { EXAM_MAN } from 'Constants/ActionTypes';
const initialState = {
    selectTestPaperVal: JSON.stringify({}),
};
// 用户配置
const index = (state = initialState, action) => {
    switch (action.type) {
        // 试卷选中
        case EXAM_MAN.SELECT_TEST_PAPER_FUN:
            return {
                ...state,
                selectTestPaperVal: action.selectTestPaperVal,
            };
        default:
            return state;
    }
};
export default index;
