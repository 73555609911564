/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2019-09-21 15:37:07
 * @LastEditors: tanwei
 * @LastEditTime: 2020-07-30 13:00:24
 * @FilePath: /trkj_jypt/src/utils/utils.js
 */
import { Base64 } from 'js-base64';
/**
 * 公用方法
 */
//返回日期时间
function formateDate(time) {
  if (!time) return '';
  let date = new Date(time);
  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
}

function randomKey() {
  let Reno = Math.random(1000 * 100) + "";
  let keyId = Reno.replace(/^0\./, "");
  return keyId;
}

/**
 * @description 格式化人民币 eg:10000 => 10,000.00
 * @param {*} money
 */
function formatMoney(money, decimal = 2) {
  if (Number(money) === 0 || Number.isNaN(Number(money))) return '0.00';
  return Number(money)
    .toFixed(decimal)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
/*
* 树形结构反查父节点
* */
function treeFindPath(tree, func, path = []) {
  if (!tree) return []
  for (const data of tree) {
    // 这里按照你的需求来存放最后返回的内容吧
    path.push(data.name)
    if (func(data)) return path
    if (data.children) {
      const findChildren = treeFindPath(data.children, func, path)
      if (findChildren.length) return findChildren
    }
    path.pop()
  }
  return []
}

const authority = arr => {
  // 传入值为数组,返回类似{authA:ture,authB:false}的对象，传入值为字符串直接返回true/false

  let authorityArr = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : [];
  if (!authorityArr) {
    return false
  }
  if (Array.isArray(arr)) {
    const authorityRes = {};
    arr.forEach(item => {
      authorityRes[item] = authorityArr.includes(item);
    });
    return authorityRes;
  }
  if (typeof arr === "string") {
    return authorityArr.includes(arr);
  }
  return false;
};
function minutesOrSecond(timestamp) {
  if (timestamp >= 60) {
    const minutes = parseInt(timestamp / 60)
    const second = timestamp % 60;
    return second ? `${minutes}分钟${second}秒` : `${minutes}分钟`
  } else {
    return `${timestamp}秒`
  }

}
function getUrlParams(url, name = '') { // 不传name返回所有值，否则返回对应值
  if (url.indexOf('?') === 1) { return false; }
  url = url.substr(1);
  url = url.split('&');
  var nameres = null;
  // 获取全部参数及其值
  for (var i = 0; i < url.length; i++) {
    var info = url[i].split('=');
    var obj = {};
    obj[info[0]] = decodeURIComponent(info[1]);
    url[i] = obj;
  }
  // 如果传入一个参数名称，就匹配其值
  if (name) {
    for (let i = 0; i < url.length; i++) {
      for (const key in url[i]) {
        if (key === name) {
          nameres = url[i][key];
        }
      }
    }
  } else {
    nameres = url;
  }
  // 返回结果
  return nameres;
}
/**
 * 二进制流导出
 */
function exportStreamFile(data, filename) {
  try {
    const content = data;
    // const blob = new Blob([content]);
    const blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const fileName = filename;
    if ('download' in document.createElement('a')) {
      // 非IE下载
      const elink = document.createElement('a');
      elink.download = fileName;
      elink.style.display = 'none';
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
    } else {
      // IE10+下载
      navigator.msSaveBlob(blob, fileName);
    }
  } catch (error) {
    console.error('导出异常');
  }
}

const strToBase64 = (str) => Base64.encode(str);
export {
  formateDate,
  randomKey,
  formatMoney,
  treeFindPath,
  authority,
  minutesOrSecond,
  getUrlParams,
  exportStreamFile,
  strToBase64,
}
