/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2019-09-21 13:14:06
 * @LastEditors: tanwei
 * @LastEditTime: 2020-06-19 09:52:53
 * @FilePath: \trkj_jypt\src\containers\admin.js
 */
import React from 'react'
import { Row, Col, Spin, message } from 'antd';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import commonActions from "Actions/common/commonActions.js";
import loginActions from "Actions/login/loginActions.js";
import Header from 'Components/Header'
// import TopTab from 'Components/TopTab'
// import Footer from 'Components/Footer'
import NavLeft from 'Components/NavLeft'
import './admin.less'
import 'Static/common.less'
import 'Static/css/icon.less'
import 'Static/icon/iconfont.css'

class Admin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShow: true
    };
    this.authArr = [];
  }
  componentDidMount() {
    // 获取全局配置
    let authorization = localStorage.getItem('access-token');
    if (authorization) {
      this.getUserInfo()
    }
    // 获取全局配置
    message.config({
      maxCount: 3
    })
  }

  getUserInfo = async () => {
    const { commonActions, loginActions, history } = this.props;
    this.setState({ isShow: false })
    await loginActions.loginCheck()
      .then(res => {
        const { code, data } = res.payload;
        if (code === 200) {
          if (data && data.user_info) {
            const superAdminOrAdmin = data.user_info.role.findIndex(item => item.value === 'superAdmin')
            data.user_info.superAdminOrAdmin = superAdminOrAdmin !== -1
            localStorage.setItem("user_info", JSON.stringify(data.user_info));
          }
        }
      })
    await commonActions.getUserInfo()
      .then(res => {
        const { code, data } = res.payload;
        if (code === 200) {
          const autoInfo = data && Array.isArray(data) ? data.filter(val => val.auth_type === "api")[0].children : []
          const menuIInfo = data && Array.isArray(data) ? data.filter(val => val.auth_type === "menu")[0].children : []
          localStorage.setItem("menu", JSON.stringify(menuIInfo));
          this.recursiveAuth(autoInfo);
          localStorage.setItem("auth", JSON.stringify(this.authArr));
          this.setState({ isShow: true })
        }
      }).catch(err => {
        message.warning('权限异常，已退出登录')
        localStorage.clear();
        history.push('/login');
      });
  }
  recursiveAuth = (api) => {
    if (api && Array.isArray(api) && api.length > 0) {
      api.forEach(val => {
        if (val.children) {
          this.recursiveAuth(val.children)
        }
        if (val.rule) {
          this.authArr.push(val.rule)
        }
      })
    }
  };
  render() {
    const { collapsed, children } = this.props;
    const { isShow } = this.state
    return (
      isShow ?
        <Row className="container">
          <Col span={4} className={collapsed ? "nav-left nav-left-shrink" : "nav-left"}>
            <NavLeft />
          </Col>
          <Col className={collapsed ? "main main-expand" : "main"}>
            <Header />
            {/*<TopTab />*/}
            <Row className="content">
              {children}
            </Row>
            {/*<Footer/>*/}
          </Col>
        </Row>
        : <Spin spinning={!isShow} size={"large"} tip={'系统加载中，请稍等！'}><div style={{ width: '100vw', height: '100vh' }} ></div></Spin>
    );
  }
}
const mapStateToProps = state => {
  return {
    collapsed: state.common.collapsed,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Admin));
