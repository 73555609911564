import { COMMON } from 'Constants/ActionTypes';
import _ from 'lodash'
const initialState = {
  collapsed: false,
  openKeys: [],
  allConfigData: [],
  isImportStatus: false,
  importInfo: [],
  menuItemData: [{
    name: "首页",
    rule: "/index"
  }],
  targetKey: null,
  topTabShow: false,
  uploadResInfo: [],
  uploadThumbInfo: [],
  selectedRowIds: [],
};
// 公共配置
const nvaLeftStatus = (state = initialState, action) => {
  switch (action.type) {
    // 菜单栏状态
    case COMMON.NAV_LEFT_STATUS:
      return {
        ...state,
        collapsed: action.bol,
        openKeys: action.openKeys,
        targetKey: action.targetKey,
      };
    // topTab显示隐藏状态
    case COMMON.TOP_TAB_STATUS:
      return {
        ...state,
        topTabShow: action.topTabShow,
      };
    // 菜单和topTab联动
    case COMMON.SET_NAV_MENU_ITEM:
      return {
        ...state,
        // 数组中对象去重，title和key完全相等才去重
        menuItemData: _.uniqWith([...state.menuItemData, action.menuItemData], _.isEqual),

      };
    // 菜单和topTab联动（删除）
    case COMMON.DELETE_NAV_MENU_ITEM:
      if (action.targetMenuItem) {
        return {
          ...state,
          // 删除菜单中某一项，完全匹配才删除
          menuItemData: _.differenceWith(state.menuItemData, [action.targetMenuItem], _.isEqual)
        };
      } else {
        return {
          ...state,
          menuItemData: [{
            name: "首页",
            rule: "/index"
          }]
        };
      }
    // 菜单和topTab选中值
    case COMMON.SET_NAV_MENU_ITEM_TARGET:
      return {
        ...state,
        targetKey: action.targetKey,
      };
    //获取全局配置
    case `${COMMON.GET_ALL_CONFIG}_SUCCESS`:
      return {
        ...state,
        allConfigData: action.payload.data
      };
    // 上传文件
    case COMMON.SET_UPLOAD_RESOURCE_INFO:
      return {
        ...state,
        uploadResInfo: action.uploadResInfo,
      };
    // 上传缩略图
    case COMMON.SET_UPLOAD_THUMB_INFO:
      return {
        ...state,
        uploadThumbInfo: action.uploadThumbInfo,
      };
    // 关联选择项
    case COMMON.SET_SELECTION_CHANGE:
      return {
        ...state,
        selectedRowIds: action.selectedRowIds,
      };
    // 导入是否成功
    case COMMON.IS_IMPORT_STATUS:
      return {
        ...state,
        isImportStatus: action.isImportStatus,
      };
    // 导入是否成功
    case COMMON.SET_IMPORT_INFO:
      return {
        ...state,
        importInfo: action.importInfo,
      };
    default:
      return state;
  }
};

export default nvaLeftStatus;
