/*
 * 公共actions
 * */
import { COMMON } from "Constants/ActionTypes";

const actions = {
  // 菜单栏状态
  nvaLeftStatus: function (bol, openKeys, targetKey) {
    return {
      type: COMMON.NAV_LEFT_STATUS,
      bol,
      openKeys,
      targetKey,
    };
  },
  // 菜单栏状态
  topTabStatus: function (topTabShow) {
    return {
      type: COMMON.TOP_TAB_STATUS,
      topTabShow,
    };
  },
  //根据菜单栏和设置topTab
  setMenuItem: function (menuItemData) {
    return {
      type: COMMON.SET_NAV_MENU_ITEM,
      menuItemData,
    };
  },
  //菜单栏和topTab选中目标
  setTargetMenuItem: function (targetKey) {
    return {
      type: COMMON.SET_NAV_MENU_ITEM_TARGET,
      targetKey,
    };
  },
  // 获取阿里云OSS签名授权
  getUploadOssAuth: function (data) {
    return {
      type: COMMON.DELETE_NAV_MENU_ITEM,
      payload: {
        request: {
          method: "get",
          url: "/open/upload/get_ali_oss_auth",
          params: data,
        },
      },
    };
  },
  //根据菜单栏和删除topTab
  deleteMenuItem: function (targetMenuItem) {
    return {
      type: COMMON.DELETE_NAV_MENU_ITEM,
      targetMenuItem,
    };
  },
  // 获取地区树
  getAreaTree: function () {
    return {
      type: COMMON.GET_AREA_TREE,
      payload: {
        request: {
          method: "get",
          url: "/open/area/tree",
        },
      },
    };
  },
  // 获取学校树
  getSchoolTree: function () {
    return {
      type: COMMON.GET_SCHOOL_TREE,
      payload: {
        request: {
          method: "get",
          url: "/open/school/tree",
        },
      },
    };
  },
  // 获取机构树
  getOrgTree: function (data) {
    return {
      type: COMMON.GET_ORG_TREE,
      payload: {
        request: {
          method: "get",
          url: `/open/org/tree`,
          params: data,
        },
      },
    };
  },
  // 获取权限分类树
  getAuthClass: function (data) {
    return {
      type: COMMON.GET_AUTH_CLASS,
      payload: {
        request: {
          method: "get",
          url: `/open/auth/tree`,
          params: data,
        },
      },
    };
  },
  // 获取用户信息
  getUserInfo: function () {
    return {
      type: COMMON.GET_USER_INFO,
      payload: {
        request: {
          method: "get",
          url: `/open/login/get_auth`,
        },
      },
    };
  },
  // 获取所有课程
  getCourseData: function (data) {
    return {
      type: COMMON.GET_COURSE_DATA,
      payload: {
        request: {
          method: "get",
          url: `/open/course/get_all`,
          params: data,
        },
      },
    };
  },
  // 获取所有课程(客户端管理使用)
  //  getCourseV2Data: function (data) {
  //   return {
  //     type: COMMON.GET_COURSE_DATA,
  //     payload: {
  //       request: {
  //         method: "get",
  //         url: `/open/course_v2/get_all`,
  //         params: data
  //       }
  //     }
  //   }
  // },

  // 获取所有三方应用分组
  getAppGroupAll: function () {
    return {
      type: COMMON.GET_APP_GROUP_ALL,
      payload: {
        request: {
          method: "get",
          url: `/open/app_group/get_all`,
        },
      },
    };
  },

  // 获取所有课程分类
  getAllCourseClass: function (data) {
    return {
      type: COMMON.GET_ALL_COURSE_CLASS,
      payload: {
        request: {
          method: "get",
          url: `/open/course_category/get_all`,
          params: data,
        },
      },
    };
  },
  // 获取所有课程分类(客户端管理使用)
  getAllCourseV2Class: function (data) {
    return {
      type: COMMON.GET_ALL_COURSE_CLASS,
      payload: {
        request: {
          method: "get",
          url: `/open/course_category_v2/get_all`,
          params: data,
        },
      },
    };
  },
  // 获取所有试卷类型
  getAllExamClass: function (data) {
    return {
      type: COMMON.GET_ALL_EXAM_CLASS,
      payload: {
        request: {
          method: "get",
          url: `/open/exam_category/get_all`,
          params: data,
        },
      },
    };
  },
  // 上传文件
  setUploadResourceInfo: function (uploadResInfo) {
    return {
      type: COMMON.SET_UPLOAD_RESOURCE_INFO,
      uploadResInfo,
    };
  },
  // 上传缩略图
  setUploadThumbInfo: function (uploadThumbInfo) {
    return {
      type: COMMON.SET_UPLOAD_THUMB_INFO,
      uploadThumbInfo,
    };
  },
  // 多选操作选中值
  setSelectionChange: function (selectedRowIds) {
    return {
      type: COMMON.SET_SELECTION_CHANGE,
      selectedRowIds,
    };
  },
  // 修改自己的密码
  modifyMyPw: function (data) {
    return {
      type: COMMON.MODIFY_MY_PW,
      payload: {
        request: {
          method: "put",
          url: `/open/user/change_password`,
          data,
        },
      },
    };
  },
  // 导入是否成功
  isImportStatus: function (isImportStatus) {
    return {
      type: COMMON.IS_IMPORT_STATUS,
      isImportStatus,
    };
  },
  // 导入数据
  setImportInfo: function (importInfo) {
    return {
      type: COMMON.SET_IMPORT_INFO,
      importInfo,
    };
  },
  // token失效测试
  getTokenDemo: function () {
    return {
      type: COMMON.GET_TOKEN_DEMO,
      payload: {
        request: {
          method: "get",
          url: `/api/token/demo`,
        },
      },
    };
  },
};

export default actions;
