import React from 'react'
import { Menu, Icon } from 'antd';
import { NavLink, withRouter } from 'react-router-dom'
import { bindActionCreators } from "redux";
import { connect } from 'react-redux'
import commonActions from "Actions/common/commonActions.js";
import logo from 'Static/images/logo.png'
import './index.less'

const SubMenu = Menu.SubMenu;

class NavLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuTreeNode: []
    };
  }

  componentDidMount() {
    const { commonActions, collapsed, openKeys, history } = this.props;
    this.getMenuList();
    commonActions.nvaLeftStatus(collapsed, openKeys, history.location.pathname)
  }
  getMenuList = () => {
    const menuList = localStorage.getItem("menu") ? JSON.parse(localStorage.getItem("menu")) : [];
    const MENU_LIST = Array.isArray(menuList) ? menuList : [];
    const menuTreeNode = this.renderMenu(MENU_LIST);
    this.rootSubmenuKeys = [];
    MENU_LIST.forEach(val => {
      if (val.children) {
        this.rootSubmenuKeys.push(val.id)
      }
    });
    this.setState({
      menuTreeNode
    })
  };
  onClickMenuItem = (MenuItem) => {
    const { commonActions, collapsed, openKeys } = this.props;
    let targetKey = MenuItem.key;
    let menuItemData = {
      name: MenuItem.item.props.title,
      rule: MenuItem.key,
    };
    commonActions.nvaLeftStatus(collapsed, openKeys, targetKey);
    commonActions.setMenuItem(menuItemData);
    commonActions.setTargetMenuItem(targetKey)
  };
  // 菜单渲染
  renderMenu(data) {
    return data.map((item) => {
      if (item.children) {
        return (
          <SubMenu
            title={
              <span>{item.hasOwnProperty('icon') && item.icon ? <Icon type={item.icon} /> : null}
                <span>{item.name}</span>
              </span>}
            key={item.rule ? item.rule : item.id}
          >
            {this.renderMenu(item.children)}
          </SubMenu>
        )
      }
      return <Menu.Item title={item.name} key={item.rule}>
        <NavLink
          to={item.rule}
          replace
        >
          {item.hasOwnProperty('icon') && item.icon ? <Icon type={item.icon} /> : null}
          <span>{item.name}</span>
        </NavLink>
      </Menu.Item>
    })
  }
  // 不能同时展开2个
  onOpenChange = (openKey) => {
    const { commonActions, collapsed, openKeys, targetKey } = this.props;
    const latestOpenKey = openKey.find(key => openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      commonActions.nvaLeftStatus(collapsed, openKey, targetKey)
    } else {
      commonActions.nvaLeftStatus(collapsed, latestOpenKey ? [latestOpenKey] : [], targetKey)
    }
  };

  render() {
    const { collapsed, openKeys, targetKey } = this.props;
    const { menuTreeNode } = this.state;
    return (
      <div>
        <NavLink
          to="/index"
        >
          <div className="logo">
            <img src={logo} alt="" />
            <h1 className="logoInfo" style={collapsed ? { display: "none" } : null}>安全里云平台</h1>
          </div>
        </NavLink>

        <Menu
          openKeys={openKeys}
          onOpenChange={this.onOpenChange}
          theme="dark"
          mode="inline"
          selectedKeys={[targetKey]}
          className="menuLeft"
          inlineCollapsed={collapsed}
          onClick={this.onClickMenuItem}
        >
          {menuTreeNode}
          {/* 特殊挂起的菜单 */}
          <Menu.Item title={'安全管控'} key={'securityControl'}>
            <a
              href={'http://aql.paxy365.com/Public/login.html'}
              target="_blank" rel="noopener noreferrer"
            >
              <Icon type="safety-certificate" />
              <span>安全管控</span>
            </a>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}



const mapStateToProps = state => {
  return {
    menu: state.common.menu,
    collapsed: state.common.collapsed,
    openKeys: state.common.openKeys,
    targetKey: state.common.targetKey,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(NavLeft));
