/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2019-09-21 13:15:55
 * @LastEditors: tanwei
 * @LastEditTime: 2020-06-21 13:00:31
 * @FilePath: \trkj_jypt\src\app.js
 */
//全局外壳
import React, { Component } from "react";
import {
  Layout,
  ConfigProvider,//国际化
} from "antd";
import { withRouter } from 'react-router-dom'
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
const { Content } = Layout;

class App extends Component {
  render() {
    const { children } = this.props;
    return (
      <ConfigProvider locale={zhCN}>
        <div className="container">
          <Layout style={{ height: '100vh' }}>
            <Content className="main-content">
              {children}
            </Content>
          </Layout>
        </div>
      </ConfigProvider>
    );
  }
}

export default withRouter(App);
