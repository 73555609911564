import React,{Component} from 'react'
import {
  Modal,
  Spin,
  Button,
} from 'antd'
class WrapModal extends Component{
  constructor(props){
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this)
  }
  handleOk(e){
    const { handleOk } = this.props;
    handleOk()
  }
  handleCancel(e){
    const { handleCancel } = this.props;
    handleCancel()
  }
  render(){
    const {
      modalConfig,
      visible,
      content,
      wrapClassName,
      loading,
      handleOkDisabled,
      handleCancelDisabled,
      footLeftInfo,
      footRightInfo,
    } = this.props;

    return(
      <Modal
        {...modalConfig}
        wrapClassName={wrapClassName}
        visible={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        keyboard={false}
        destroyOnClose={true}
        maskClosable={false}
        closable={!handleCancelDisabled}
        centered={true}
        footer={[
          footLeftInfo?footLeftInfo:null,
          handleCancelDisabled?null:<Button key="back" onClick={this.handleCancel}>{footRightInfo && footRightInfo.length>0?footRightInfo[0]:"取消"}</Button>,
          footRightInfo && footRightInfo.length>1 && footRightInfo[1] === "没有"?null:<Button disabled={handleOkDisabled} key="submit" type="primary" loading={loading} onClick={this.handleOk}>
            {footRightInfo && footRightInfo.length>1?footRightInfo[1]:"确定"}
          </Button>,
        ]}
      >
        <Spin spinning={loading}>{content}</Spin>
      </Modal>
    )
  }
}
export default WrapModal
