import { LOGIN } from 'Constants/ActionTypes'

const actions = {
  // 登录
  login: function (data) {
    return {
      type: LOGIN.LOGIN,
      payload: {
        request: {
          method: "post",
          url: "/open/login/login",
          data
        }
      }
    }
  },
  // 登录验证
  loginCheck: function () {
    return {
      type: LOGIN.LOGIN_CHECK,
      payload: {
        request: {
          method: "get",
          url: "/open/login/check",
        }
      }
    }
  },
  // 验证码
  getCaptcha: function (url) {
    return {
      type: LOGIN.LOGIN_CHECK,
      payload: {
        request: {
          method: "get",
          url: `${url}`,
        }
      }
    }
  },

  // 登出
  loginOut: function () {
    return {
      type: LOGIN.LOGIN_OUT,
      payload: {
        request: {
          method: "get",
          url: "/open/login/logout",
        }
      }
    }
  },
};

export default actions
