import { SETTING } from 'Constants/ActionTypes'
const initialState = {

}

const setting = (state = initialState, action) => {
    switch (action.type) {
        case `${SETTING.CE_SHI}_SUCCESS`:
            return {
                ...state,
                employeeList: action.payload.data
            }

        default:
            return state
    }
};

export default setting