import loadable from '@loadable/component'

const Demo = loadable(() => import('Containers/demo'));
const Login = loadable(() => import('Containers/login'));
const Index = loadable(() => import('Containers/index'));
const Error404 = loadable(() => import('Containers/errorPages/error404'));
const Region = loadable(() => import('Containers/settingAdmin/region'));
const Institution = loadable(() => import('Containers/settingAdmin/institution'));
const School = loadable(() => import('Containers/settingAdmin/school'));
const Classes = loadable(() => import('Containers/settingAdmin/classes'));
const SysConfig = loadable(() => import('Containers/settingAdmin/sysConfig'));
const LoginLog = loadable(() => import('Containers/log/loginLog'));
const ApplicationGroup = loadable(() => import('Containers/settingAdmin/applicationGroup'));
const Application = loadable(() => import('Containers/settingAdmin/application'));
const Overview = loadable(() => import('Containers/settingAdmin/overview'));
const UserMan = loadable(() => import('Containers/userAdmin/userMan'));
const PrivilegeRole = loadable(() => import('Containers/userAdmin/privilegeRole'));
const PrivilegeRoleGroup = loadable(() => import('Containers/userAdmin/privilegeRoleGroup'));
const PrivilegeMan = loadable(() => import('Containers/userAdmin/privilegeMan'));
const ResourceMan = loadable(() => import('Containers/resource/resourceMan'));
const ResourceManSort = loadable(() => import('Containers/resource/resourceMan/sort'));
const ResourceClass = loadable(() => import('Containers/resource/resourceClass'));
const CourseMan = loadable(() => import('Containers/course/courseMan'));
const CourseClass = loadable(() => import('Containers/course/courseClass'));
const SubjectMan = loadable(() => import('Containers/questionBank/subjectMan'));
const TestPaperMan = loadable(() => import('Containers/questionBank/testPaperMan'));
const ExamClass = loadable(() => import('Containers/questionBank/examClass'));

const ExamMan = loadable(() => import('Containers/questionBank/examMan'));
const ExamResults = loadable(() => import('Containers/questionBank/examResults'));
const ExamResultDetails = loadable(() => import('Containers/questionBank/examResultDetails'));

const SimulationGame = loadable(() => import('Containers/questionBank/simulationGame'));
const SimGameResults = loadable(() => import('Containers/questionBank/simGameResults'));
const SimGameResultDetails = loadable(() => import('Containers/questionBank/simGameResultDetails'));
// 小程序管理
const CampusKnowledge = loadable(() => import('Containers/applets/campusKnowledge'));
const CampusKnowledgeClass = loadable(() => import('Containers/applets/campusKnowledgeClass'));
const HomeVisit = loadable(() => import('Containers/applets/homeVisit'));
// 新闻管理
const NewsClass = loadable(() => import('Containers/news/newsClass'));
const NewsList = loadable(() => import('Containers/news/newsList'));

// 测评管理
const ExaminationQuestions = loadable(() => import('Containers/testEvaluation/examinationQuestions'));
const ExaminationQuestionsClass = loadable(() => import('Containers/testEvaluation/examinationQuestionsClass'));
const TestPaper = loadable(() => import('Containers/testEvaluation/testPaper'));
const TestPaperDetails = loadable(() => import('Containers/testEvaluation/testPaper/details'));

const QuestionsStatistics = loadable(() => import('Containers/testEvaluation/statistics'));

/**
 * 统计
 */
// 可视化大屏统计(省市区大屏)
const LargeVisualizationScreen = loadable(() => import('Containers/largeVisualizationScreen'));
// 大屏护蕾统计
const ProtectChildStatistics = loadable(() => import('Containers/protectChildStatistics'));
// 校级大屏
const SchoolLargeVisualizationScreen = loadable(() => import('Containers/schoolLargeVisualizationScreen'));
// 省市区统计
const ProvinceStatistics = loadable(() => import('Containers/statistics/province'));
const CityStatistics = loadable(() => import('Containers/statistics/city'));
const AreaStatistics = loadable(() => import('Containers/statistics/area/allArea'));
const AreaStatisticsRank = loadable(() => import('Containers/statistics/area/rank'));
// 校级统计
const AllSchoolStatistics = loadable(() => import('Containers/statistics/school/allSchool/school'));
const AllGradeStatistics = loadable(() => import('Containers/statistics/school/allSchool/rank'));
const AllTeacherStatistics = loadable(() => import('Containers/statistics/school/allTeacher'));
const AllStudentStatistics = loadable(() => import('Containers/statistics/school/allStudent'));
const AllClassesStatistics = loadable(() => import('Containers/statistics/school/allClasses'));
// 家长统计
const AllParent = loadable(() => import('Containers/statistics/parent'));

// 活动管理
const ActivityTheme = loadable(() => import('Containers/activity/theme'));
const ActivitySchool = loadable(() => import('Containers/activity/school'));
const ActivityParticipationUser = loadable(() => import('Containers/activity/participationUser'));

// 客户端管理
const ClientCourseMan = loadable(() => import('Containers/client/courseMan'));
const ClientCourseManChapter = loadable(() => import('Containers/client/courseMan/chapter'));
const ClientCourseCategory = loadable(() => import('Containers/client/courseCategory'));

const routesConfig = [
  {
    path: "/login",
    component: Login,
    exact: false,
  },
  {
    path: "/error/404",
    component: Error404,
    exact: false,
  }, {
    path: "/largeVisualizationScreen/:region",
    component: LargeVisualizationScreen,
    exact: true,
    auth: true,
  },
  {
    path: "/protectChild/:region",
    component: ProtectChildStatistics,
    exact: true,
    auth: true,
  },
  {
    path: "/statistics/school/largeVisualizationScreen",
    component: SchoolLargeVisualizationScreen,
    exact: true,
    auth: true,
  },
  {
    path: 'Admin',
    component: 'Admin',
    auth: true,
    children: [{
      path: ["/index", "/"],
      component: Index,
      exact: true,
    }, {
      path: "/demo",
      component: Demo,
      exact: true,
    }, {
      path: "/settingAdmin/region",
      component: Region,
      exact: true,
    }, {
      path: "/settingAdmin/institution",
      component: Institution,
      exact: true,
    }, {
      path: "/settingAdmin/school",
      component: School,
      exact: true,
    }, {
      path: "/settingAdmin/classes",
      component: Classes,
      exact: true,
    }, {
      path: "/settingAdmin/sysConfig",
      component: SysConfig,
      exact: true,
      // title:"系统导入"
    }, {
      path: "/settingAdmin/applicationGroup",
      component: ApplicationGroup,
      exact: true,
    }, {
      path: "/settingAdmin/application",
      component: Application,
      exact: true,
    }, {
      path: "/settingAdmin/overview",
      component: Overview,
      exact: true,
    }, {
      path: "/log/loginLog",
      component: LoginLog,
      exact: true,
    }, {
      path: "/userAdmin/userMan",
      component: UserMan,
      exact: true,
    }, {
      path: "/userAdmin/privilegeRoleGroup",
      component: PrivilegeRoleGroup,
      exact: true,
    }, {
      path: "/userAdmin/privilegeRole",
      component: PrivilegeRole,
      exact: true,
    }, {
      path: "/userAdmin/privilegeMan",
      component: PrivilegeMan,
      exact: true,
    }, {
      path: "/resource/resourceClass",
      component: ResourceClass,
      exact: true,
    }, {
      path: "/resource/resourceMan",
      component: ResourceMan,
      exact: true,
    },
    {
      path: "/resource/resourceMan/sort/:id",
      component: ResourceManSort,
      exact: true,
    },
    {
      path: "/course/courseMan",
      component: CourseMan,
      exact: true,
    }, {
      path: "/course/courseClass",
      component: CourseClass,
      exact: true,
    }, {
      path: "/testEvaluation/examinationQuestions",
      component: ExaminationQuestions,
      exact: true,
    },
    {
      path: "/testEvaluation/examinationQuestionsClass",
      component: ExaminationQuestionsClass,
      exact: true,
    },
    {
      path: "/testEvaluation/testPaper",
      component: TestPaper,
      exact: true,
    },
    {
      path: "/testEvaluation/testPaper/details/:id",
      component: TestPaperDetails,
      exact: true,
    },
    {
      path: "/testEvaluation/statistics",
      component: QuestionsStatistics,
      exact: true,
    }, {
      path: "/questionBank/subjectMan",
      component: SubjectMan,
      exact: true,
    }, {
      path: "/questionBank/testPaperMan",
      component: TestPaperMan,
      exact: true,
    }, {
      path: "/questionBank/examClass",
      component: ExamClass,
      exact: true,
    }, {
      path: "/questionBank/examMan",
      component: ExamMan,
      exact: true,
    }, {
      path: "/questionBank/examResults/:exam_id",
      component: ExamResults,
      exact: true,
    }, {
      path: "/questionBank/examResultDetails/:exam_id/:user_id",
      component: ExamResultDetails,
      exact: true,
    }, {
      path: "/questionBank/simulationGame",
      component: SimulationGame,
      exact: true,
    }, {
      path: "/questionBank/simGameResults/:simGame_id",
      component: SimGameResults,
      exact: true,
    }, {
      path: "/questionBank/simGameResultDetails/:simGame_id/:id",
      component: SimGameResultDetails,
      exact: true,
    },
    {
      path: "/statistics/province",
      component: ProvinceStatistics,
      exact: true,
    },
    {
      path: "/statistics/city",
      component: CityStatistics,
      exact: true,
    },
    {
      path: "/statistics/area",
      component: AreaStatistics,
      exact: true,
    },
    {
      path: "/statistics/area/rank",
      component: AreaStatisticsRank,
      exact: true,
    },
    {
      path: "/statistics/school/allSchool",
      component: AllSchoolStatistics,
      exact: true,
    }, {
      path: "/statistics/school/rank",
      component: AllGradeStatistics,
      exact: true,
    }, {
      path: "/statistics/school/allTeacher",
      component: AllTeacherStatistics,
      exact: true,
    },
    {
      path: "/statistics/school/allStudent",
      component: AllStudentStatistics,
      exact: true,
    },
    {
      path: "/statistics/school/allClasses",
      component: AllClassesStatistics,
      exact: true,
    },
    {
      path: "/statistics/parent",
      component: AllParent,
      exact: true,
    },
    {
      path: "/applets/campusKnowledge",
      component: CampusKnowledge,
      exact: true,
    },
    {
      path: "/applets/campusKnowledgeClass",
      component: CampusKnowledgeClass,
      exact: true,
    },
    {
      path: "/applets/homeVisit",
      component: HomeVisit,
      exact: true,
    },
    {
      path: "/news/list",
      component: NewsList,
      exact: true,
    },
    {
      path: "/news/class",
      component: NewsClass,
      exact: true,
    },
    {
      path: "/activity/theme",
      component: ActivityTheme,
      exact: true,
    },
    {
      path: "/activity/school",
      component: ActivitySchool,
      exact: true,
    },
    {
      path: "/activity/participationUser",
      component: ActivityParticipationUser,
      exact: true,
    },
    {
      path: "/client/courseMan",
      component: ClientCourseMan,
      exact: true,
    },
    {
      path: "/client/courseMan/chapter/:id",
      component: ClientCourseManChapter,
      exact: true,
    },
    {
      path: "/client/courseCategory",
      component: ClientCourseCategory,
      exact: true,
    },
    ]
  },
];
export default routesConfig
