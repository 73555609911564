import { combineReducers } from "redux";
import setting from "./setting";
import common from "./common/common";
import testPaper from "./testPaper/testPaper";
import classes from "./classes/classes";
import examMan from "./examMan";

//引入各个reducer
export default combineReducers({
  setting,
  common,
  testPaper,
  classes,
  examMan,
})
