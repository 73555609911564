import { TEST_PAPER_MAN } from 'Constants/ActionTypes';
const initialState = {
    stepStatus: 1,
    examPaperId:"",
};
// 用户配置
const testPaper = (state = initialState, action) => {

    switch (action.type) {
        // 关联选择项
        case TEST_PAPER_MAN.SET_STEP_STATUS:
            return {
                ...state,
                stepStatus: action.stepStatus,
                examPaperId: action.examPaperId,
            };
        default:
            return state;
    }
};
export default testPaper;
