/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2019-10-27 10:29:04
 * @LastEditors: tanwei
 * @LastEditTime: 2020-03-22 18:58:06
 * @FilePath: \trkj_jypt\src\components\HocPrivateRoute\index.js
 */
import React from 'react';
import { withRouter } from 'react-router-dom';
function Index(WrappedComponent) {
  if (!WrappedComponent) {
    throw new Error("HocPrivateRoute组件缺少参数(默认为'react-router-dom'的Route)");
  }
  return withRouter(
    class extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          isAuthenticated: true
        }
      }
      componentDidMount() {
        let access_token = localStorage.getItem('access-token');
        let isAuthenticated = access_token || false;
        this.setState({
          isAuthenticated
        });

        if (!isAuthenticated) {
          const { history } = this.props;
          localStorage.clear();
          history.replace("/login");
        }
      }

      render() {
        const { isAuthenticated } = this.state;
        return isAuthenticated ?
          <WrappedComponent {...this.props} />
          : <div className="home-wrap">请重新登录</div>;
      }
    }
  )
}


export default Index;
